export const CHANGE_VIEW = "changeView";
export const CHANGE_SUB_VIEW = "changeSubView";

export const changeView = newView => {
  return {
    type: CHANGE_VIEW,
    payload: {
      acive_view: newView
    }
  };
};

export const changeSubView = newSubView => {
  return {
    type: CHANGE_SUB_VIEW,
    payload: {
      sub_view: newSubView
    }
  };
};
