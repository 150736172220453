import { CHANGE_VIEW, CHANGE_SUB_VIEW } from "../actions/navbarActions";

const appReducer = (state = {}, { type, payload }) => {
  // let newState = {};
  switch (type) {
    case CHANGE_VIEW:
      return Object.assign({}, state, payload);
    case CHANGE_SUB_VIEW:
      switch (payload.sub_view) {
        case "servicios":
        case "prductos":
        case "testimonios":
        case "clientes":
        case "video":
          return Object.assign({}, state, {
            active_view: "portafolio",
            sub_view: payload.sub_view
          });
        case "estuduios":
        case "experienciaLaboral":
        case "habilidades":
        case "idiomas":
          return Object.assign({}, state, {
            active_view: "curriculum",
            sub_view: payload.sub_view
          });
        default:
          return state;
      }
    default:
      return state;
  }
};

export default appReducer;
